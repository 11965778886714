import * as S from "./styled";
import React from "react";

export const AppWrapper = ({ children, isResume }) => {
	return (
		<>
			<S.GlobalStyle />
			<S.Wrapper isResume={isResume}>{children}</S.Wrapper>
		</>
	);
};
