import { breakpoints } from "../../styles/util/breakpoints";
import styled, { createGlobalStyle } from "styled-components";
import { css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    ${[...Array(5)].map(
		(_, i) =>
			`h${i + 1} {
                margin: 0 0 1rem;
                font-size: ${4 - i}rem;
            }`
	)}

	a {
		color: #0000ee;
	}

	html,
	body {
		margin: 0;
		max-width: 100vw;
	}

	/**
	* @see https://www.joshwcomeau.com/css/custom-css-reset/
	*/

	/*
	1. Use a more-intuitive box-sizing model.
	*/
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}
	/*
		2. Remove default margin
	*/
	* {
		margin: 0;
	}
	/*
		3. Allow percentage-based heights in the application
	*/
	html,
	body {
		height: 100%;
	}
	/*
		Typographic tweaks!
		4. Add accessible line-height
		5. Improve text rendering
	*/
	body {
		line-height: 1.1;
		-webkit-font-smoothing: antialiased;
	}
	/*
		6. Improve media defaults
	*/
	img,
	picture,
	video,
	canvas,
	svg {
		display: block;
		max-width: 100%;
	}
	/*
		7. Remove built-in form typography styles
	*/
	input,
	button,
	textarea,
	select {
		font: inherit;
	}
	/*
		8. Avoid text overflows
	*/
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		overflow-wrap: break-word;
	}
	/*
		9. Create a root stacking context
	*/
	#root,
	#__next {
		isolation: isolate;
	}

	* {
		text-transform: lowercase;
	}

	p {
		margin: 1rem 0;
	}

	ul {
		padding-inline-start: 16px;

		${breakpoints.tablet`
			padding-inline-start: 40px;
		`}
	}
`;

export const Wrapper = styled.div`
	display: flex;

	justify-content: center;
	margin: 0 16px;
	padding: 16px 0 32px;

	${(props) =>
		!props.isResume
			? css`
					${breakpoints.tablet`
						max-width: 660px;
						margin-left: 48px;
					`}
			  `
			: css`
					${breakpoints.tablet`
						margin: 0 48px;
					`}
			  `}
`;
