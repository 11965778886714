import { css } from "styled-components";

const breakpointsMap = {
	mobile: 320,
	tablet: 481,
	laptop: 769,
	desktop: 1025,
};

export const breakpoints = Object.entries(breakpointsMap).reduce(
	(acc, [key, breakpoint]) => ({
		...acc,
		[key]: (...args) =>
			css`
				@media (min-width: ${breakpoint}px) {
					${css(args[0], ...args.slice(1))}
				}
			`,
	}),
	{}
);
